<script module lang="ts">
	export declare namespace IconNamespace {
		type IconComponent = typeof import('~icons/*').default;

		type Props = {
			colour?: TColours;
			stroke?: TColours;
			size?: number;
			icon: IconComponent;
			rotate?: number;
			absolute?: boolean;
			paintOrder?: 'fill' | 'stroke';
		};
	}
</script>

<script lang="ts">
	import type { TColours } from '$lib/data/colours';
	import { styles } from '$lib/utilities/core/styles';
	import classNames from 'classnames';
	const {
		colour,
		stroke,
		paintOrder = 'fill',
		size,
		icon,
		rotate,
		absolute,
	}: IconNamespace.Props = $props();

	const classes = $derived(
		classNames('Icon', {
			[`Icon--${colour}`]: colour,
			[`Icon--size`]: size,
			'Icon--absolute': absolute,
			'Icon--stroke': stroke,
			[`Icon--paintOrder-${paintOrder}`]: paintOrder,
		}),
	);

	const style = $derived(
		styles({
			'--Icon--stroke': stroke && `var(--${stroke})`,
			'--Icon--size': size,
		}),
	);

	const Icon = $derived(icon);
</script>

<icon class={classes} {style}>
	<Icon {rotate} />
</icon>

<style lang="scss">
	@import '$lib/styles/colours.scss';

	.Icon {
		display: inline-flex;
		transition: all 0.2s var(--easing);

		&--paintOrder {
			&-stroke {
				:global(svg) {
					paint-order: stroke;
				}
			}

			&-fill {
				:global(svg) {
					paint-order: fill;
				}
			}
		}

		&--stroke {
			:global(svg) {
				stroke: var(--Icon--stroke);
			}
		}

		&--absolute {
			position: absolute;
		}

		@each $colour in $colours {
			&--#{$colour} {
				color: var(--#{$colour});
			}
		}

		&--size {
			font-size: calc(var(--padding) * var(--Icon--size));
		}
	}
</style>
