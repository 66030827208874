import EmojioneV1SoftIceCream from '$lib/assets/images/imageIcons/emojione-v1_soft-ice-cream.png';
import FluentEmojiPencil from '$lib/assets/images/imageIcons/fluent-emoji--pencil.png';
import FluentEmojiWastebasket from '$lib/assets/images/imageIcons/fluent-emoji--wastebasket.png';
import FluentEmojiBookMark from '$lib/assets/images/imageIcons/fluent-emoji-bookmark-tabs.png';
import FluentEmojiBubble from '$lib/assets/images/imageIcons/fluent-emoji-bubble.png';
import FluentEmojiBullseye from '$lib/assets/images/imageIcons/fluent-emoji-bullseye.png';
import FluentEmojiCalendar from '$lib/assets/images/imageIcons/fluent-emoji-calendar.png';
import FluentEmojiCheckMark from '$lib/assets/images/imageIcons/fluent-emoji-check-mark.png';
import FluentEmojiCloud from '$lib/assets/images/imageIcons/fluent-emoji-cloud.png';
import FluentEmojiCook from '$lib/assets/images/imageIcons/fluent-emoji-cook.png';
import FluentEmojiEMail from '$lib/assets/images/imageIcons/fluent-emoji-e-mail.png';
import FluentEmojiGear from '$lib/assets/images/imageIcons/fluent-emoji-gear.png';
import FluentEmojiGraph from '$lib/assets/images/imageIcons/fluent-emoji-graph.png';
import FluentEmojiInformation from '$lib/assets/images/imageIcons/fluent-emoji-information.png';
import FluentEmojiLightbulb from '$lib/assets/images/imageIcons/fluent-emoji-light-bulb.png';
import FluentEmojiLock from '$lib/assets/images/imageIcons/fluent-emoji-locked-with-key.png';
import FluentEmojiPlus from '$lib/assets/images/imageIcons/fluent-emoji-plus.png';
import FluentEmojiRight from '$lib/assets/images/imageIcons/fluent-emoji-right.png';
import FluentEmojiMovieCamera from '$lib/assets/images/imageIcons/fluent-emoji-video.png';
import FluentEmojiWarning from '$lib/assets/images/imageIcons/fluent-emoji-warning.png';
import FluentEmojiApple from '$lib/assets/images/imageIcons/fluent-emoji_apple.png';
import FluentEmojiBanana from '$lib/assets/images/imageIcons/fluent-emoji_banana.png';
import FluentEmojiBeans from '$lib/assets/images/imageIcons/fluent-emoji_beans.png';
import FluentEmojiBellPepper from '$lib/assets/images/imageIcons/fluent-emoji_bell-pepper.png';
import FluentEmojiBerries from '$lib/assets/images/imageIcons/fluent-emoji_berries.png';
import FluentEmojiBlue from '$lib/assets/images/imageIcons/fluent-emoji_blue-circle.png';
import FluentEmojiBroccoli from '$lib/assets/images/imageIcons/fluent-emoji_broccoli.png';
import FluentEmojiCake from '$lib/assets/images/imageIcons/fluent-emoji_cake.png';
import FluentEmojiCandy from '$lib/assets/images/imageIcons/fluent-emoji_candy.png';
import FluentEmojiCarrot from '$lib/assets/images/imageIcons/fluent-emoji_carrot.png';
import FluentEmojiCauliflowerCustom from '$lib/assets/images/imageIcons/fluent-emoji_cauliflower-custom.png';
import FluentEmojiCheeseWedge from '$lib/assets/images/imageIcons/fluent-emoji_cheese-wedge.png';
import FluentEmojiChicken from '$lib/assets/images/imageIcons/fluent-emoji_chicken.png';
import FluentEmojiChocolateBar from '$lib/assets/images/imageIcons/fluent-emoji_chocolate-bar.png';
import FluentEmojiCoffee from '$lib/assets/images/imageIcons/fluent-emoji_coffee.png';
import FluentEmojiCookies from '$lib/assets/images/imageIcons/fluent-emoji_cookies.png';
import FluentEmojiCowFace from '$lib/assets/images/imageIcons/fluent-emoji_cow-face.png';
import FluentEmojiCow from '$lib/assets/images/imageIcons/fluent-emoji_cow.png';
import FluentEmojiCupWithStraw from '$lib/assets/images/imageIcons/fluent-emoji_cup-with-straw.png';
import FluentEmojiCutOfMeat from '$lib/assets/images/imageIcons/fluent-emoji_cut-of-meat.png';
import FluentEmojiDroplet from '$lib/assets/images/imageIcons/fluent-emoji_droplet.png';
import FluentEmojiEgg from '$lib/assets/images/imageIcons/fluent-emoji_egg.png';
import FluentEmojiEggplant from '$lib/assets/images/imageIcons/fluent-emoji_eggplant.png';
import FluentEmojiFish from '$lib/assets/images/imageIcons/fluent-emoji_fish.png';
import FluentEmojiGlassOfMilk from '$lib/assets/images/imageIcons/fluent-emoji_glass-of-milk.png';
import FluentEmojiGreen from '$lib/assets/images/imageIcons/fluent-emoji_green-circle.png';
import FluentEmojiHammerAndWrench from '$lib/assets/images/imageIcons/fluent-emoji_hammer-and-wrench.png';
import FluentEmojiHighVoltage from '$lib/assets/images/imageIcons/fluent-emoji_high-voltage.png';
import FluentEmojiIceCream from '$lib/assets/images/imageIcons/fluent-emoji_ice-cream.png';
import FluentEmojiLeafyGreen from '$lib/assets/images/imageIcons/fluent-emoji_leafy-green.png';
import FluentEmojiLemon from '$lib/assets/images/imageIcons/fluent-emoji_lemon.png';
import FluentEmojiMango from '$lib/assets/images/imageIcons/fluent-emoji_mango.png';
import FluentEmojiMeat from '$lib/assets/images/imageIcons/fluent-emoji_meat.png';
import FluentEmojiNotebook from '$lib/assets/images/imageIcons/fluent-emoji_notebook.png';
import FluentEmojiNuts from '$lib/assets/images/imageIcons/fluent-emoji_nuts.png';
import FluentEmojiOpenBook from '$lib/assets/images/imageIcons/fluent-emoji_open-book.png';
import FluentEmojiOrangeCustom from '$lib/assets/images/imageIcons/fluent-emoji_orange-custom.png';
import FluentEmojiPear from '$lib/assets/images/imageIcons/fluent-emoji_pear.png';
import FluentEmojiPig from '$lib/assets/images/imageIcons/fluent-emoji_pig.png';
import FluentEmojiPineapple from '$lib/assets/images/imageIcons/fluent-emoji_pineapple.png';
import FluentEmojiPotato from '$lib/assets/images/imageIcons/fluent-emoji_potato.png';
import FluentEmojiRoastedSweetPotato from '$lib/assets/images/imageIcons/fluent-emoji_roasted-sweet-potato.png';
import FluentStarIcon from '$lib/assets/images/imageIcons/fluent-emoji_star-struck.png';
import FluentEmojiTea from '$lib/assets/images/imageIcons/fluent-emoji_tea.png';
import FluentEmojiTomato from '$lib/assets/images/imageIcons/fluent-emoji_tomato.png';
import FluentEmojiTropicalDrink from '$lib/assets/images/imageIcons/fluent-emoji_tropical-drink.png';
import FluentEmojiYellow from '$lib/assets/images/imageIcons/fluent-emoji_yellow-circle.png';

export const _ImageIconSources = {
	fluent: {
		apple: FluentEmojiApple,
		wasteBasket: FluentEmojiWastebasket,
		banana: FluentEmojiBanana,
		plus: FluentEmojiPlus,
		pencil: FluentEmojiPencil,
		email: FluentEmojiEMail,
		beans: FluentEmojiBeans,
		bellPepper: FluentEmojiBellPepper,
		berries: FluentEmojiBerries,
		blue: FluentEmojiBlue,
		bookMark: FluentEmojiBookMark,
		broccoli: FluentEmojiBroccoli,
		bubble: FluentEmojiBubble,
		bullseye: FluentEmojiBullseye,
		cake: FluentEmojiCake,
		calendar: FluentEmojiCalendar,
		candy: FluentEmojiCandy,
		carrot: FluentEmojiCarrot,
		cauliflowerCustom: FluentEmojiCauliflowerCustom,
		checkMark: FluentEmojiCheckMark,
		cheeseWedge: FluentEmojiCheeseWedge,
		chicken: FluentEmojiChicken,
		chocolateBar: FluentEmojiChocolateBar,
		cloud: FluentEmojiCloud,
		coffeeCustom: FluentEmojiCoffee,
		cook: FluentEmojiCook,
		cookies: FluentEmojiCookies,
		cow: FluentEmojiCow,
		cowFace: FluentEmojiCowFace,
		cupWithStraw: FluentEmojiCupWithStraw,
		cutOfMeat: FluentEmojiCutOfMeat,
		droplet: FluentEmojiDroplet,
		egg: FluentEmojiEgg,
		eggplant: FluentEmojiEggplant,
		fish: FluentEmojiFish,
		gear: FluentEmojiGear,
		glassOfMilk: FluentEmojiGlassOfMilk,
		graph: FluentEmojiGraph,
		green: FluentEmojiGreen,
		hammerAndWrench: FluentEmojiHammerAndWrench,
		highVoltage: FluentEmojiHighVoltage,
		iceCream: FluentEmojiIceCream,
		information: FluentEmojiInformation,
		leafyGreen: FluentEmojiLeafyGreen,
		lemon: FluentEmojiLemon,
		lightbulb: FluentEmojiLightbulb,
		lock: FluentEmojiLock,
		mango: FluentEmojiMango,
		meat: FluentEmojiMeat,
		movieCamera: FluentEmojiMovieCamera,
		notebook: FluentEmojiNotebook,
		nuts: FluentEmojiNuts,
		openBook: FluentEmojiOpenBook,
		orangeCustom: FluentEmojiOrangeCustom,
		pear: FluentEmojiPear,
		pig: FluentEmojiPig,
		pineapple: FluentEmojiPineapple,
		potato: FluentEmojiPotato,
		right: FluentEmojiRight,
		roastedSweetPotato: FluentEmojiRoastedSweetPotato,
		startStruck: FluentStarIcon,
		tea: FluentEmojiTea,
		tomato: FluentEmojiTomato,
		tropicalDrink: FluentEmojiTropicalDrink,
		warning: FluentEmojiWarning,
		yellow: FluentEmojiYellow,
	},
	emojiOne: {
		softIceCream: EmojioneV1SoftIceCream,
	},
} as const;
